html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  font-weight: 500 !important;
}

.absgp-form-wrapper {
  width: 500px;
}